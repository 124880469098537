import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import axios from 'axios';

const TRANSLATE_API_KEY = process.env.REACT_APP_TRANSLATE_API_KEY;

function Success() {
  const [planDetails, setPlanDetails] = useState(null);
  const [translations, setTranslations] = useState({});
  const [userLanguage, setUserLanguage] = useState('en');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        const detectedLanguage = data.languages?.split(',')[0] || 'en';
        setUserLanguage(detectedLanguage);
      })
      .catch(() => setUserLanguage('en'));
  }, []);

  useEffect(() => {
    const translateTexts = async () => {
      const texts = {
        successTitle: 'Subscription Successful',
        processing: 'Processing your subscription...',
        thankYou: 'Thank you for subscribing to the',
        unlimitedCredits: 'You now have unlimited credits for check-ins and check-outs.',
        receivedCredits: 'You have received credits for check-ins and check-outs.'
      };

      if (userLanguage === 'en') {
        setTranslations(texts);
        return;
      }

      try {
        const translationPromises = Object.keys(texts).map(key =>
          axios.post(`https://translation.googleapis.com/language/translate/v2?key=${TRANSLATE_API_KEY}`, {
            q: texts[key],
            target: userLanguage
          }).then(response => ({ key, translation: response.data.data.translations[0].translatedText }))
        );

        const results = await Promise.all(translationPromises);
        const translationResults = results.reduce((acc, { key, translation }) => {
          acc[key] = translation;
          return acc;
        }, {});

        setTranslations(translationResults);
      } catch (error) {
        console.error('Error translating texts:', error);
        setTranslations(texts);
      }
    };
    translateTexts();
  }, [userLanguage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, 5000);
    return () => clearTimeout(timer);
  }, [navigate]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const plan = queryParams.get('plan');
    const credits = queryParams.get('credits');

    if (plan && credits) {
      const subscription = {
        plan,
        credits: credits === 'Infinity' ? Infinity : parseInt(credits, 10),
        startDate: new Date().toISOString(),
      };
      localStorage.setItem('subscription', JSON.stringify(subscription));
      setPlanDetails(subscription);
    }
  }, [location]);

  return (
    <div className="success-container">
      <h2>{translations.successTitle || 'Subscription Successful'}</h2>
      {planDetails ? (
        <div>
          <p>{translations.thankYou || 'Thank you for subscribing to the'} <strong>{planDetails.plan.toUpperCase()}</strong> plan!</p>
          <p>
            {planDetails.credits === Infinity
              ? translations.unlimitedCredits || 'You now have unlimited credits for check-ins and check-outs.'
              : `${translations.receivedCredits || 'You have received'} ${planDetails.credits} credits for check-ins and check-outs.`}
          </p>
        </div>
      ) : (
        <p>{translations.processing || 'Processing your subscription...'}</p>
      )}
    </div>
  );
}

export default Success;
