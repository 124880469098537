// src/CheckIn.js
import React, { useState, useEffect } from 'react';
import { addCheckInRecord, getAllActiveCheckIns, removeCheckInRecord } from './db';
import './App.css';
import axios from 'axios';

// Function to decode HTML entities
const decodeHtmlEntities = (str) => {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = str;
  return textarea.value;
};

export default function CheckIn() {
  const [message, setMessage] = useState('');
  const [activeCheckIns, setActiveCheckIns] = useState([]);
  const [translations, setTranslations] = useState({});
  const [userLanguage, setUserLanguage] = useState('en'); // Default to English
  const [inputTagId, setInputTagId] = useState('');


  const texts = {
    pageTitle: 'NFC Check-In',
    clearSuccess: 'All active check-ins cleared successfully.',
    readyMessage: 'Ready to scan NFC for check-in',
    nfcUnsupported: 'NFC is not supported on this device.',
    nfcActivated: 'Scan your NFC Card to Check-In.',
    checkInSuccessful: 'Check-in successful at',
    readingError: 'Error reading NFC tag.',
    activeCheckIns: 'Active Check-Ins:',
    tagId: 'Tag ID:',
    checkInTime: 'Check-In Time:',
  };

  const TRANSLATE_API_KEY = process.env.REACT_APP_TRANSLATE_API_KEY;

  const logoUrl = 'https://www.minutebar.eu/beahost2025/images/logo.jpg';

  useEffect(() => {
    const fetchUserLanguage = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        const languageMap = {
          US: 'en', FR: 'fr', ES: 'es', DE: 'de', IT: 'it', NL: 'nl',
          CN: 'zh', JP: 'ja', RU: 'ru', PT: 'pt', BR: 'pt', IN: 'hi',
          NO: 'no', SE: 'sv', FI: 'fi',
        };
        setUserLanguage(languageMap[data.country_code] || 'en');
      } catch (error) {
        console.error('Failed to detect user language:', error);
        setUserLanguage('en');
      }
    };
    fetchUserLanguage();
  }, []);

  useEffect(() => {
    const translateTexts = async () => {
      if (userLanguage === 'en') {
        setTranslations(texts);
        setMessage(texts.nfcActivated);
        return;
      }

      try {
        const translationPromises = Object.keys(texts).map((key) => {
          return axios.post(
            `https://translation.googleapis.com/language/translate/v2?key=${TRANSLATE_API_KEY}`,
            {
              q: texts[key],
              target: userLanguage,
            }
          ).then((response) => ({
            key,
            translation: decodeHtmlEntities(response.data.data.translations[0].translatedText),
          }));
        });

        const results = await Promise.all(translationPromises);
        const translatedTexts = results.reduce((acc, { key, translation }) => {
          acc[key] = translation;
          return acc;
        }, {});

        setTranslations(translatedTexts);
        setMessage(translatedTexts.nfcActivated);
      } catch (error) {
        console.error('Error translating texts:', error);
        setTranslations(texts); // Fallback to English
        setMessage(texts.nfcActivated);
      }
    };

    translateTexts();
  }, [userLanguage]);

  useEffect(() => {
    startNFCScan();
    loadActiveCheckIns();
  }, []);

  const loadActiveCheckIns = async () => {
    const checkIns = await getAllActiveCheckIns();
    setActiveCheckIns(checkIns);
  };

  const startNFCScan = async () => {
  if (!('NDEFReader' in window)) {
    setMessage(translations.nfcUnsupported || texts.nfcUnsupported);
    return;
  }

  try {
    const ndef = new NDEFReader();
    await ndef.scan();
    setMessage(translations.nfcActivated || texts.nfcActivated);

    ndef.onreading = async (event) => {
      const tagId = event.serialNumber;
      const timestamp = Date.now();

      await addCheckInRecord(tagId, timestamp);

      // Update subscription credits
      const subscription = JSON.parse(localStorage.getItem('subscriptionData'));
      if (subscription && subscription.active && subscription.credits > 0) {
        subscription.credits -= 1;
        localStorage.setItem('subscriptionData', JSON.stringify(subscription));
        console.log('Updated subscription credits:', subscription.credits);
      }

      setMessage(
        `${translations.checkInSuccessful || texts.checkInSuccessful} ${new Date(
          timestamp
        ).toLocaleTimeString()} with ${tagId}`
      );
      loadActiveCheckIns();
    };

    ndef.onreadingerror = () => {
      setMessage(translations.readingError || texts.readingError);
    };
  } catch (error) {
    setMessage(`NFC error: ${error.message}`);
  }
};


  const clearAllCheckIns = () => {
   // Clear all active check-ins from localStorage
   localStorage.removeItem('activeCheckIns'); // Clear persistent storage
   setActiveCheckIns([]); // Clear state
   setMessage(translations.clearSuccess || 'All active check-ins cleared successfully.');
 };

 const handleClearCheckIn = async (inputTagId) => {
  if (!inputTagId || !inputTagId.trim()) {
    setMessage('Please enter a valid Tag ID.');
    return;
  }

  try {
    const trimmedTagId = inputTagId.trim();
    const checkInsBefore = await getAllActiveCheckIns();
    const checkInExists = checkInsBefore.some((checkIn) => checkIn.tagId === trimmedTagId);

    if (!checkInExists) {
      setMessage(`No active check-in found for Tag ID: ${trimmedTagId}`);
      return;
    }

    await removeCheckInRecord(trimmedTagId);  // Ensure proper removal
    const checkInsAfter = await getAllActiveCheckIns(); // Refresh immediately

    const stillExists = checkInsAfter.some((checkIn) => checkIn.tagId === trimmedTagId);

    if (stillExists) {
      setMessage(`Failed to remove check-in record for Tag ID: ${trimmedTagId}. Please try again.`);
    } else {
      setMessage(`Check-in record removed for Tag ID: ${trimmedTagId}`);
      setInputTagId('');
      setActiveCheckIns(checkInsAfter);
    }
  } catch (error) {
    console.error('Error removing check-in record:', error);
    setMessage('An error occurred while trying to remove the check-in record.');
  }
};



  return (
    <div className="home-container">
      <div className="content-wrapper"> {/* Transparent container */}
      <img
        src="https://www.minutebar.eu/bh2025/images/logo.png"
        alt="Minute Bar Logo"
        className="logo"
        style={{ cursor: 'pointer', width: '100px', height: '100px' }}
      />
        <h2>{translations.pageTitle || texts.pageTitle}</h2>
        <img
          src="https://www.minutebar.eu/beahost2025/OV-chipkaart_logo.png"
          alt="OV Chip Card Logo"
          className="logo"
          style={{ cursor: 'pointer', width: '75px', height: '50px' }}
        />
        <p>{message}</p>



<div className="active-checkins-section">
  <h4>Active Check-Ins</h4>
  {activeCheckIns.length === 0 ? (
    <p>No active check-ins found.</p>
  ) : (
    <ul>
      {activeCheckIns.map(({ tagId, checkInTime }) => (
        <li key={tagId}>
          <strong>Tag ID:</strong> {tagId} <br />
          <strong>Check-In Time:</strong> {checkInTime}
        </li>
      ))}
    </ul>
  )}
</div>

<div className="clear-checkin-section">
<h4>Remove Check-In Record</h4>
<input
type="text"
value={inputTagId}
onChange={(e) => setInputTagId(e.target.value)}
placeholder="Enter Tag ID"
className="tag-input"
/>
<button onClick={() => handleClearCheckIn(inputTagId)} className="button-link clear-button">
Clear Check-In
</button>
</div>


      </div>
    </div>
  );

}
