import React from 'react';

export default function Consent() {
  const redirectToSumUp = () => {
    const clientId = 'cc_classic_0iYLye80zB9LrS4PYEqooKmcspw2f'; // Replace with your actual client ID
    const redirectUri = encodeURIComponent('https://www.minutebar.eu/beahost2025/checkout'); // Your OAuth callback URL
    const scope = encodeURIComponent('payments user.app-settings transactions.history user.profile_readonly');
    const state = encodeURIComponent('your_unique_state'); // Optional: Add a unique value for CSRF protection

    const consentUrl = `https://api.sumup.com/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;
    window.location.href = consentUrl; // Redirect the user to SumUp's consent page
  };

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', textAlign: 'center' }}>
      <h1>Authorization Required</h1>
      <p>
        To use the Be A Host service with your SumUp account, we need your permission to access certain information:
      </p>
      <ul style={{ textAlign: 'left', margin: '0 auto', maxWidth: '400px' }}>
        <li>Make payments by creating and processing checkouts.</li>
        <li>View your transactions and transaction history.</li>
        <li>View and manage your SumUp mobile application settings.</li>
        <li>View your merchant profile information.</li>
      </ul>
      <p>
        By granting permission, you enable us to process payments and integrate SumUp functionality seamlessly.
      </p>
      <button
        onClick={redirectToSumUp}
        style={{
          backgroundColor: '#4CAF50',
          color: 'white',
          padding: '10px 20px',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        Authorize with SumUp
      </button>
    </div>
  );
}
