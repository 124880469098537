import React, { useState } from "react";
import "./Pricing.css";

const STRIPE_SECRET_KEY = process.env.REACT_APP_STRIPE_LIVE_KEY;

function Pricing() {
  const [loadingStates, setLoadingStates] = useState({});

  const plans = [
      {
        name: "basic",
        description: "10 days FREE Trial - 10 credits for check-ins & outs per day.\n⚠️ This application does NOT work on iOS.",
        monthlyAmount: 1000, // €10/month in cents
        yearlyAmount: 9000, // €90/year in cents (25% discount)
        credits: 10,
      },
      {
        name: "pro",
        description: "10 days FREE Trial - 50 credits for check-ins & outs per day.\n⚠️ This application does NOT work on iOS.",
        monthlyAmount: 4000, // €40/month in cents
        yearlyAmount: 36000, // €360/year in cents (25% discount)
        credits: 50,
      },
    ];

  const createProductAndPrice = async (plan, amount, interval) => {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${STRIPE_SECRET_KEY}`,
    };

    // Create Stripe Product
    const productResponse = await fetch("https://api.stripe.com/v1/products", {
      method: "POST",
      headers,
      body: new URLSearchParams({
        name: `${plan.name.charAt(0).toUpperCase() + plan.name.slice(1)} Plan`,
        description: plan.description,
      }),
    });

    const product = await productResponse.json();
    if (!product.id) {
      throw new Error(`❌ Product creation failed: ${product.error?.message || "Unknown error"}`);
    }
    console.log("✅ Product created:", product.id);

    // Create Stripe Price
    const priceResponse = await fetch("https://api.stripe.com/v1/prices", {
      method: "POST",
      headers,
      body: new URLSearchParams({
        unit_amount: `${amount}`,
        currency: "eur",
        product: product.id,
        "recurring[interval]": interval,
      }),
    });

    const price = await priceResponse.json();
    if (!price.id) {
      throw new Error(`❌ Price creation failed: ${price.error?.message || "Unknown error"}`);
    }
    console.log("✅ Price created:", price.id);
    return price.id;
  };

  const createCheckoutSession = async (priceId, plan) => {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${STRIPE_SECRET_KEY}`,
    };

    const linkResponse = await fetch("https://api.stripe.com/v1/checkout/sessions", {
      method: "POST",
      headers,
      body: new URLSearchParams({
        "line_items[0][price]": priceId,
        "line_items[0][quantity]": "1",
        "subscription_data[trial_settings][end_behavior][missing_payment_method]": "cancel",
        "subscription_data[trial_period_days]": "10",
        mode: "subscription",
        success_url: `https://www.minutebar.eu/beahost2025/success?plan=${plan.name}&credits=${plan.credits}`,
        cancel_url: "https://www.minutebar.eu/beahost2025/cancel",
      }),
    });

    const checkoutSession = await linkResponse.json();

    if (!checkoutSession.url) {
      throw new Error(
        `❌ Checkout session creation failed: ${checkoutSession.error?.message || "Unknown error"}`
      );
    }

    console.log("✅ Redirecting to Stripe Checkout...");
    window.location.href = checkoutSession.url;
  };

  const handlePayment = async (plan, amount, interval) => {
    const stateKey = `${plan.name}-${interval}`;

    try {
      setLoadingStates((prev) => ({ ...prev, [stateKey]: true }));
      const priceId = await createProductAndPrice(plan, amount, interval);
      await createCheckoutSession(priceId, plan);
    } catch (error) {
      console.error("🔥 Error creating subscription link:", error.message);
      alert(`Error: ${error.message}`);
    } finally {
      setLoadingStates((prev) => ({ ...prev, [stateKey]: false }));
    }
  };

  return (
    <div className="pricing-container">
      <h2 className="pricing-title">Pricing Plans</h2>

      {/* 🚨 iOS Compatibility Warning */}
      <p className="ios-warning">
        ⚠️ This application does NOT work on iOS devices due to the closed nature of iOS.
      </p>

      <div className="pricing-grid">
        {plans.map((plan) => (
          <div key={plan.name} className="pricing-item">
            <h3>{plan.name.charAt(0).toUpperCase() + plan.name.slice(1)} Plan</h3>
            <p>{plan.description}</p>

            <div className="pricing-buttons-wrapper">
  {/* Monthly Subscription Button */}
  <button
    className="generate-button subscription-button"
    onClick={() => handlePayment(plan, plan.monthlyAmount, "month")}
    disabled={loadingStates[`${plan.name}-month`]}
  >
    {loadingStates[`${plan.name}-month`]
      ? "Redirecting..."
      : `Subscribe Monthly (€${plan.monthlyAmount / 100})`}
  </button>

  {/* Discount Label */}
  <span className="discount-note">Save 25%</span>

  {/* Yearly Subscription Button (Now centered) */}
  <button
    className="generate-button subscription-button"
    onClick={() => handlePayment(plan, plan.yearlyAmount, "year")}
    disabled={loadingStates[`${plan.name}-year`]}
  >
    {loadingStates[`${plan.name}-year`]
      ? "Redirecting..."
      : `Subscribe Yearly (€${plan.yearlyAmount / 100})`}
  </button>
</div>

          </div>
        ))}
      </div>
    </div>
  );
}

export default Pricing;
