import React, { useState, useEffect } from 'react';
import './App.css';
import axios from 'axios';

const TRANSLATE_API_KEY = process.env.REACT_APP_TRANSLATE_API_KEY;

export default function Privacy() {
  const [translations, setTranslations] = useState({});
  const [userLanguage, setUserLanguage] = useState('en');

  const texts = {
    pageTitle: 'Privacy Statement for the Check-In Application',
    intro: 'At Minute Bar, we take your privacy seriously. This privacy statement outlines how we handle and protect your data in the Check-In Application.',
    dataCollectionTitle: 'Data Collection and Purpose',
    dataCollection: 'We only collect minimal data necessary to operate the app, such as the UID from NFC tags and timestamp information. No PII is collected.',
    zkpTitle: 'Zero-Knowledge Protocol for Enhanced Privacy',
    zkp: 'We implement the Zero-Knowledge Protocol (ZKP) to validate transactions without accessing or sharing UID or timestamps.',
    dataStorageTitle: 'Data Storage and Security',
    dataStorage: 'Data is stored locally using IndexedDB to minimize risks associated with centralized storage.',
    limitedAccessTitle: 'Limited Access and Usage of Data',
    limitedAccess: 'Data is used solely for calculating stay duration and fees, with strict access limited to the user’s device.',
    dataDeletionTitle: 'Data Deletion',
    dataDeletion: 'Users can delete their data at any time to ensure no residual information remains.',
    commitmentTitle: 'Commitment to Privacy',
    commitment: 'We prioritize privacy by design, ensuring transparency and data security.',
    contact: 'For any questions regarding this privacy statement, please contact us at',
  };

  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        const detectedLanguage = data.languages?.split(',')[0] || 'en';
        setUserLanguage(detectedLanguage);
      })
      .catch(() => setUserLanguage('en'));
  }, []);

  useEffect(() => {
    const translateTexts = async () => {
      if (userLanguage === 'en') {
        setTranslations(texts);
        return;
      }

      try {
        const translationPromises = Object.keys(texts).map(key =>
          axios.post(`https://translation.googleapis.com/language/translate/v2?key=${TRANSLATE_API_KEY}`, {
            q: texts[key],
            target: userLanguage
          }).then(response => ({ key, translation: response.data.data.translations[0].translatedText }))
        );

        const results = await Promise.all(translationPromises);
        const translationResults = results.reduce((acc, { key, translation }) => {
          acc[key] = translation;
          return acc;
        }, {});

        setTranslations(translationResults);
      } catch (error) {
        console.error('Error translating texts:', error);
        setTranslations(texts);
      }
    };
    translateTexts();
  }, [userLanguage]);

  return (
    <div className="privacy-container">
      <h2>{translations.pageTitle || texts.pageTitle}</h2>
      <p>{translations.intro || texts.intro}</p>
      <h3>{translations.dataCollectionTitle || texts.dataCollectionTitle}</h3>
      <p>{translations.dataCollection || texts.dataCollection}</p>
      <h3>{translations.zkpTitle || texts.zkpTitle}</h3>
      <p>{translations.zkp || texts.zkp}</p>
      <h3>{translations.dataStorageTitle || texts.dataStorageTitle}</h3>
      <p>{translations.dataStorage || texts.dataStorage}</p>
      <h3>{translations.limitedAccessTitle || texts.limitedAccessTitle}</h3>
      <p>{translations.limitedAccess || texts.limitedAccess}</p>
      <h3>{translations.dataDeletionTitle || texts.dataDeletionTitle}</h3>
      <p>{translations.dataDeletion || texts.dataDeletion}</p>
      <h3>{translations.commitmentTitle || texts.commitmentTitle}</h3>
      <p>{translations.commitment || texts.commitment}</p>
      <p>{translations.contact || texts.contact} <a href="mailto:info@minutebar.eu">info@minutebar.eu</a>.</p>
    </div>
  );
}
