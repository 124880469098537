import React, { useEffect, useState } from 'react';
import axios from 'axios';

const TRANSLATE_API_KEY = process.env.REACT_APP_TRANSLATE_API_KEY;

export default function AboutMinuteBar() {
  const [translations, setTranslations] = useState({
    guestBenefitsList: [],
    hostBenefitsList: [],
    appFeatures: []
  });
  const [userLanguage, setUserLanguage] = useState('en');

  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        const detectedLanguage = data.languages?.split(',')[0] || 'en';
        setUserLanguage(detectedLanguage);
      })
      .catch(() => setUserLanguage('en'));
  }, []);

  useEffect(() => {
    const translateTexts = async () => {
      try {
        const texts = {
          aboutTitle: "Understanding the Pay-Per-Time Concept of Minute Bar and Its Impact",
          introduction: "In a world where inflation continuously erodes purchasing power, the Minute Bar concept presents an innovative, cost-effective alternative to traditional hospitality. By leveraging a pay-per-time model, guests are charged based on the duration of their stay rather than conventional pricing methods like per-drink or per-item costs. This model not only reduces overhead for hosts but also promotes fairness and transparency for guests.",
          conceptExplained: "Minute Bar is built around the principle that guests pay only for the time they spend at a venue. Upon arrival, they check in using NFC technology, which starts a timer. When they decide to leave, they check out, and the total cost is calculated based on their time spent. This system simplifies billing and creates an environment where guests can enjoy themselves without worrying about accumulating high bills.",
          combatingInflation: "One of the key advantages of Minute Bar is its ability to fight inflation. By minimizing labor costs, the concept significantly lowers operating expenses. Traditional bars and restaurants require large staffs to serve customers, prepare drinks, and clean up afterward. At Minute Bar, guests handle most of these tasks themselves, drastically reducing the need for employees.",
          byoConcept: "Additionally, the BYO (Bring Your Own) concept allows guests to bring their own beverages or snacks. This approach eliminates costly markups and provides guests with a much more affordable experience. Since there are no significant price inflations on goods, consumers enjoy cost-effective social gatherings, and hosts avoid the burden of stocking and managing inventory.",
          benefitsGuests: "Benefits for Guests:",
          guestBenefitsList: [
            "Transparent Pricing: Guests know exactly how much they will pay, as the pricing is time-based rather than item-based.",
            "Freedom and Flexibility: Guests are not pressured to order additional items to justify their stay.",
            "Self-Managed Experience: The self-service model allows guests to create a more personalized and enjoyable environment.",
            "Cost Savings: BYO options and the elimination of hidden costs lead to an overall cheaper experience."
          ],
          benefitsHosts: "Benefits for Hosts:",
          hostBenefitsList: [
            "Reduced Overhead Costs: With minimal staffing requirements, hosts can significantly lower payroll and operational expenses.",
            "Maximized Space Utilization: The time-based model ensures that spaces are occupied efficiently, generating consistent revenue.",
            "Simplified Business Operations: Automated check-in and check-out through the app streamline transactions and eliminate the need for traditional cash registers.",
            "Attracting a Cost-Conscious Audience: In an era of rising prices, consumers are actively seeking budget-friendly alternatives, making the Minute Bar concept highly attractive."
          ],
          roleApplication: "The Role of the Minute Bar Application",
          appFeatures: [
            "NFC Check-In/Out: Enables guests to start and stop their sessions effortlessly.",
            "Real-Time Billing: Provides users with a clear breakdown of costs as they spend time at the venue.",
            "Integration with Payment Methods: Supports seamless transactions through digital wallets and credit cards.",
            "Guest Analytics: Helps hosts analyze customer behaviors and optimize pricing strategies."
          ],
          futureHospitality: "The Future of Time-Based Hospitality",
          futureHospitalityDesc: "As the world shifts toward efficiency and cost-saving models, Minute Bar stands out as a revolutionary approach to social spaces. The combination of self-service, BYO, and app-based management makes it a forward-thinking solution for both guests and hosts. This model not only combats inflation but also redefines how people interact in hospitality spaces, fostering a more sustainable and accessible experience for everyone."
        };

        const translationPromises = Object.keys(texts).map(key => {
          return axios.post(`https://translation.googleapis.com/language/translate/v2?key=${TRANSLATE_API_KEY}`, {
            q: texts[key],
            target: userLanguage
          }).then(response => ({ key, translation: response.data.data.translations[0].translatedText }));
        });

        const results = await Promise.all(translationPromises);
        const translationResults = results.reduce((acc, { key, translation }) => {
          if (["guestBenefitsList", "hostBenefitsList", "appFeatures"].includes(key)) {
            acc[key] = translation.split('. ').map(item => item.trim()).filter(item => item);
          } else {
            acc[key] = translation;
          }
          return acc;
        }, {});

        setTranslations(translationResults);
      } catch (error) {
        console.error('Error translating text:', error);
      }
    };

    if (userLanguage !== 'en') {
      translateTexts();
    }
  }, [userLanguage]);

  return (
    <div>
      <h2>{translations.aboutTitle}</h2>
      <p>{translations.introduction}</p>
      <p>{translations.conceptExplained}</p>
      <p>{translations.combatingInflation}</p>
      <p>{translations.byoConcept}</p>
      <h3>{translations.benefitsGuests}</h3>
      <ul>
        {translations.guestBenefitsList.map((item, index) => <li key={index}>{item}</li>)}
      </ul>
      <h3>{translations.benefitsHosts}</h3>
      <ul>
        {translations.hostBenefitsList.map((item, index) => <li key={index}>{item}</li>)}
      </ul>
      <h3>{translations.roleApplication}</h3>
      <ul>
        {translations.appFeatures.map((item, index) => <li key={index}>{item}</li>)}
      </ul>
      <h3>{translations.futureHospitality}</h3>
      <p>{translations.futureHospitalityDesc}</p>
    </div>
  );
}
