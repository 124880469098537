import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function OAuthCallback() {
  const location = useLocation();
  const navigate = useNavigate();
  const [message, setMessage] = useState('Processing OAuth Callback...');

  useEffect(() => {
    const handleOAuthCallback = async () => {
      const queryParams = new URLSearchParams(location.search);
      const authorizationCode = queryParams.get('code');

      if (!authorizationCode) {
        console.error('Authorization code not found in the URL.');
        setMessage('Authorization code missing. Please try again.');
        return;
      }

      try {
        // Exchange the authorization code for tokens
        const response = await axios.post('https://api.sumup.com/token', {
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
          code: authorizationCode,
          grant_type: 'authorization_code',
          redirect_uri: 'http://www.minutebar.eu/beahost2025/oauth/callback',
        });

        const { access_token, refresh_token } = response.data;

        if (access_token && refresh_token) {
          localStorage.setItem('access_token', access_token);
          localStorage.setItem('refresh_token', refresh_token);

          setMessage('Tokens retrieved successfully. Fetching profile...');

          // Fetch user profile and redirect
          try {
            const merchantProfile = await getMerchantProfile();

            if (merchantProfile) {
              const { merchant_code, company_name } = merchantProfile;
              localStorage.setItem('merchant_code', merchant_code);
              localStorage.setItem('company_name', company_name);
            }

            await createCheckout();
            navigate('/checkout');
          } catch (profileError) {
            console.error('Error during OAuth flow:', profileError);
            setMessage('Error occurred during authentication. Check console for details.');
          }
        } else {
          console.error('Tokens not found in the response:', response.data);
          setMessage('Failed to retrieve tokens. Please try again.');
        }
      } catch (error) {
        console.error('OAuth token exchange failed:', error.response?.data || error.message);
        setMessage('Error during token exchange. Check console for details.');
      }
    };

    handleOAuthCallback();
  }, [location, navigate]);

  const getMerchantProfile = async () => {
    const token = localStorage.getItem('access_token');
    if (!token) throw new Error('Access token is missing.');

    try {
      const response = await axios.get('https://api.sumup.com/v0.1/me/merchant-profile', {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.error('Error retrieving merchant profile:', error.response?.data || error.message);
      throw error;
    }
  };

  const createCheckout = async () => {
    const token = localStorage.getItem('access_token');
    if (!token) {
      setMessage('No access token found. Please authenticate with SumUp.');
      return;
    }

    try {
      const checkoutData = {
        amount: 2.0,
        currency: 'EUR',
        checkout_reference: `ref_${Date.now()}`,
        description: 'Test Checkout',
        merchant_code: localStorage.getItem('merchant_code'),
        purpose: 'CHECKOUT',
        return_url: 'https://www.minutebar.eu/beahost2025',
      };

      const response = await axios.post('https://api.sumup.com/v0.1/checkouts', checkoutData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setMessage('Checkout created successfully.');
    } catch (error) {
      console.error('Error creating checkout:', error.response?.data || error.message);
      setMessage('Error creating checkout. Check console for details.');
    }
  };

  return <div>{message}</div>;
}
