import React, { useEffect, useState } from 'react';
import axios from 'axios';

const TRANSLATE_API_KEY = process.env.REACT_APP_TRANSLATE_API_KEY;

export default function About() {
  const [translations, setTranslations] = useState({});
  const [userLanguage, setUserLanguage] = useState('en');

  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        const detectedLanguage = data.languages.split(',')[0] || 'en';
        setUserLanguage(detectedLanguage);
      })
      .catch(() => setUserLanguage('en'));
  }, []);

  useEffect(() => {
    const translateTexts = async () => {
      try {
        const texts = {
          aboutTitle: "About Minute Bar Check-In/Out Application",
          welcome: "Welcome to the Minute Bar Host App! This app is developed by Minute Bar®, a trusted provider of innovative time-tracking solutions.",
          howItWorks: "How the App Works",
          nfcCheckIn: "NFC Check-In: Guests can scan their NFC-enabled card (e.g., OV-Chip Card) or tag upon arrival to check in. The time will start tracking automatically.",
          nfcCheckOut: "NFC Check-Out: When guests are ready to leave, they can scan the same tag to check out or multiple cards after each other. A summary of the time spent and the total cost based on the rate is provided.",
          transparentPricing: "Transparent Pricing: The app calculates the total time used in the space and applies the transparent rate of 4.50 per hour.",
          benefits: "Benefits",
          benefitsDesc: "This app simplifies time tracking and billing for both customers and space holders, ensuring accurate charges for the time spent in shared spaces. It streamlines operations, allowing businesses to focus on delivering a quality experience for their guests.",
          installing: "Installing the App on Your Device",
          installationSteps: "To install the app on your device for easy access:",
          noteIos: "Note: Unfortunately, the app currently does not work on iPhone/iOS devices. This is because NFC technology is part of Apple's closed system and is not accessible for third-party applications. The app has been extensively tested and works seamlessly on Samsung/Android devices.",
          developedBy: "Developed by Minute Bar®",
          futureImplementations: "Possible Future Implementations",
          sumup: "SumUp: We have the opportunity & knowledge to connect the latest SumUp Payment Terminal to this application.",
          crypto: "Crypto: We could implement Crypto Payment Options (e.g., Bitcoin, Ether, Litecoin) in the application.",
          contact: "Contact Us",
          contactUsDesc: "If you have any questions or need assistance with the app, please reach out to our support team at info@minutebar.eu.",
          step1: "Open the app in the Google Chrome browser on your Android device.",
          step2: "Tap the menu icon (three dots) in the top right corner of the browser window.",
          step3: "Select 'Add to Home screen' from the menu.",
          step4: "Follow the prompt to install the app on your device's home screen for quick access.",
          step5: "Latest versions of Chrome show auto prompts at the top of the screen when visiting React Applications, asking to install the Application."
        };

        const translationPromises = Object.keys(texts).map(key => {
          return axios.post(`https://translation.googleapis.com/language/translate/v2?key=${TRANSLATE_API_KEY}`, {
            q: texts[key],
            target: userLanguage
          }).then(response => ({ key, translation: response.data.data.translations[0].translatedText }));
        });

        const results = await Promise.all(translationPromises);
        const translationResults = results.reduce((acc, { key, translation }) => {
          acc[key] = translation;
          return acc;
        }, {});

        setTranslations(translationResults);
      } catch (error) {
        console.error('Error translating text:', error);
      }
    };

    if (userLanguage !== 'en') {
      translateTexts();
    }
  }, [userLanguage]);

  return (
    <div>
      <h2>{translations.aboutTitle || "About Minute Bar Check-In/Out Application"}</h2>
      <p>{translations.welcome || "Welcome to the Minute Bar Host App!"}</p>
      <h3>{translations.howItWorks || "How the App Works"}</h3>
      <ul>
        <li>{translations.nfcCheckIn}</li>
        <li>{translations.nfcCheckOut}</li>
        <li>{translations.transparentPricing}</li>
      </ul>
      <h3>{translations.benefits}</h3>
      <p>{translations.benefitsDesc}</p>
      <h3>{translations.installing}</h3>
      <p>{translations.installationSteps}</p>
      <ul>
        <li>{translations.step1}</li>
        <li>{translations.step2}</li>
        <li>{translations.step3}</li>
        <li>{translations.step4}</li>
        <li>{translations.step5}</li>
      </ul>
      <p>{translations.noteIos}</p>
      <h3>{translations.developedBy}</h3>
      <h3>{translations.futureImplementations}</h3>
      <ul>
        <li>{translations.sumup}</li>
        <li>{translations.crypto}</li>
      </ul>
      <h3>{translations.contact}</h3>
      <p>{translations.contactUsDesc}</p>
    </div>
  );
}
