// src/db.js
export const openDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("CheckInDB", 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains("checkIns")) {
        db.createObjectStore("checkIns", { keyPath: "tagId" });
      }
    };

    request.onsuccess = (event) => {
      console.log("Database opened successfully.");
      resolve(event.target.result);
    };

    request.onerror = (event) => {
      console.error(`Database error: ${event.target.errorCode}`);
      reject(`Database error: ${event.target.errorCode}`);
    };
  });
};

export const addCheckInRecord = async (tagId, timestamp) => {
  const db = await openDB();
  const transaction = db.transaction("checkIns", "readwrite");
  const store = transaction.objectStore("checkIns");

  const record = { tagId, timestamp, isCheckIn: true };
  store.put(record);
  console.log("Check-in record added:", record);
  await transaction.complete;
};

export const getCheckInRecord = async (tagId) => {
  const db = await openDB();
  const transaction = db.transaction("checkIns", "readonly");
  const store = transaction.objectStore("checkIns");

  const result = await new Promise((resolve, reject) => {
    const request = store.get(tagId);
    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(`Error fetching record for tagId ${tagId}`);
  });
  console.log("Fetched check-in record:", result);
  return result;
};

export const removeCheckInRecord = async (tagId) => {
  const db = await openDB();
  const transaction = db.transaction("checkIns", "readwrite");
  const store = transaction.objectStore("checkIns");

  const request = store.delete(tagId.toString().trim());

  return new Promise((resolve, reject) => {
    request.onsuccess = () => {
      console.log(`Check-in record removed for tagId: ${tagId}`);
      resolve();
    };
    request.onerror = (event) => {
      console.error(`Error removing check-in record for tagId: ${tagId}`, event.target.error);
      reject(event.target.error);
    };
  });
};



export const getAllActiveCheckIns = async () => {
  const db = await openDB();
  const transaction = db.transaction("checkIns", "readonly");
  const store = transaction.objectStore("checkIns");
  const checkIns = [];

  return new Promise((resolve, reject) => {
    const cursorRequest = store.openCursor();

    cursorRequest.onsuccess = (event) => {
      const cursor = event.target.result;
      if (cursor) {
        const data = cursor.value;
        if (data.isCheckIn) {
          checkIns.push({
            tagId: String(data.tagId).trim(),
            checkInTime: new Date(data.timestamp).toLocaleTimeString(),
          });
        }
        cursor.continue();
      } else {
        console.log("Active check-ins fetched:", checkIns);
        resolve(checkIns);
      }
    };

    cursorRequest.onerror = (event) => {
      console.error("Error fetching active check-ins:", event.target.error);
      reject(event.target.error);
    };
  });
};
