import React, { useState, useEffect } from 'react';
import { getCheckInRecord, removeCheckInRecord, getAllActiveCheckIns } from './db';
import './App.css';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const clientId = 'cc_classic_0iYLye80zB9LrS4PYEqooKmcspw2f';
const clientSecret = 'cc_sk_classic_hfQTn4xJg4VVcgsmdp0AZ2SuAbKgVR9S9ibyRNI8hyU7REWDEx';
const redirectUri = 'http://www.minutebar.eu/beahost2025/oauth/callback';
const API_KEY = 'sup_pk_uAjK7eFWnGPMj6jKd1y7aKC3HdcIYEcX9'
const merchantCode = 'MEYZXRTU';

const texts = {
  pageTitle: 'Check Out',
  sumUp: 'Connect to Solo Terminal',
  readyMessage: 'Ready to scan NFC for check-out',
  connectSumUp: 'Connect to SumUp Solo',
  nfcUnsupported: 'NFC is not supported on this device.',
  nfcActivated: 'Scan your NFC Card to Check-Out.',
  checkOutSuccessful: 'Check-out successful for Tag ID:',
  readingError: 'Error reading NFC tag.',
  activeCheckIns: 'Active Check-Ins:',
  checkedOutCards: 'Checked-Out Cards:',
  tagId: 'Tag ID:',
  timeSpent: 'Time Spent:',
  cost: 'Cost:',
  totalSummary: 'Total Summary',
  totalTimeSpent: 'Total Time Spent:',
  totalAmount: 'Total Amount to be Paid:',
  findReader: 'Find Solo Reader',
  payTotal: 'Pay Total via Solo',
  paymentStarted: 'Payment started on Solo Reader',
  paymentFailed: 'Failed to start checkout. Please ensure the reader is connected and online.',
  noPairedReader: 'No paired Solo reader available.',
  readerExpired: 'Reader pairing expired. Please re-pair the device.',
  noReaderFound: 'No Solo reader found.',
  readerFetchFailed: 'Failed to fetch reader details. Please check your connection.',
  pairDevice: 'Pair Solo Reader',
  enterPairingCode: 'Enter Pairing Code:',
  pairNow: 'Pair Now',
  checkPaymentStatus: 'Check Payment Status',
  scanTag: "Scan a NFC tag",
  scanMessageBuy: "Scan a NFC Tag to buy minutes"
};

export default function CheckOut() {
  const [message, setMessage] = useState('');
  const [checkedOutCards, setCheckedOutCards] = useState([]);
  const [activeCheckIns, setActiveCheckIns] = useState([]);
  const [translations, setTranslations] = useState(texts);
  const [transactionId, setTransactionId] = useState('');
  const [reader, setReader] = useState(null);
  const [pairingCode, setPairingCode] = useState('');
  const [showPairingDialog, setShowPairingDialog] = useState(false);
  const location = useLocation();
  const [selectedTagId, setSelectedTagId] = useState(null);
  const [minutesToBuy, setMinutesToBuy] = useState(15);

  const pricePerMinute = 0.1;
  const minuteOptions = [15, 30, 60, 90, 120];

  /*
  useEffect(() => {
         // Check if SumUp sent transaction info
         const params = new URLSearchParams(location.search);
         if (params.get("status") === "SUCCESS") {
             setMessage("Payment was successful!");
         } else if (params.get("status") === "FAILED") {
             setMessage("Payment failed. Please try again.");
         }
     }, [location]);
*/

  useEffect(() => {
    loadActiveCheckIns();
    startNFCScan();
    //getClientCredentialsToken(); // Fetch token on page load
  }, []);

  /**
   * 🔹 1️⃣ GET ACCESS TOKEN USING CLIENT CREDENTIALS
   */
   const getClientCredentialsToken = async () => {
   try {
     const response = await axios.post(
       'https://api.sumup.com/token',
       new URLSearchParams({
         grant_type: 'client_credentials',
         client_id: clientId,
         client_secret: clientSecret,
         scope: 'readers.read' // 🔥 Explicitly request reader access scope
       }),
       { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
     );

     console.log('Response Token Call: ', response);

     // ✅ Corrected Destructuring
     const access_token = response.data.access_token;

     const response1 = await axios.get(
       `https://api.sumup.com/v0.1/me`,
       {
         headers: { Authorization: `Bearer sup_sk_pcFn5WmqSHfhO91Okdll2jl7oVWyXzUjo`},
       }
     );

     console.log('Response ME test: ', response1)

     console.log('AccessToken:', access_token);
     localStorage.setItem('access_token', access_token);
     //setMessage('Access token retrieved successfully.');

     // ✅ Pass token to `fetchPairedReader`
     await fetchPairedReader();
   } catch (error) {
     console.error('Error fetching access token:', error.response?.data || error.message);
     setMessage('Failed to retrieve access token.');
   }
 };



  const fetchPairedReader = async () => {
  //  console.log('Amount Passed:', amount)

    try {

      const response = await axios.get(
        `https://api.sumup.com/v0.1/merchants/${merchantCode}/readers`,
        {
          headers: { Authorization: `Bearer sup_sk_pcFn5WmqSHfhO91Okdll2jl7oVWyXzUjo`},
        }
      );

      console.log('Response Paired Devices: ', response)

      const device = response.data.items[0].id;
      console.log('Device: ', device)
      localStorage.setItem('device', device);


    //  await initiateCheckout(); // Try to fetch reader again

    } catch (error) {
      console.error('Error fetching paired readers:', error.response?.data || error.message);
      setMessage(translations.readerFetchFailed);
    }
  };

  const initiateCheckout = async (amountInCents, minutesSpent) => {
    //console.log("Initiating checkout for device:", deviceId);
    // Ensure amount is an integer in cents
   //const parsedAmount = parseInt(amount, 10);

    try {
      const response = await axios.post(
          'https://api.sumup.com/v0.1/merchants/MEYZXRTU/readers/rdr_1EA1J3XFX485QTWHAGX9WDSB2D/checkout',
          // '{"total_amount": {"currency": "EUR", "minor_unit": 2, "value": 1000}, "description": "Minute Bar Checkout", "pay_to_terminal": true, "return_url": "https://www.minutebar.eu/checkout"}',
          {
            'total_amount': {
              'currency': 'EUR',
              'minor_unit': 2,
              'value': amountInCents
            },
            'description': 'Minute Bar Checkout | Minutes Spend: ' +  minutesSpent,
            'pay_to_terminal': true,
            'return_url': 'https://www.minutebar.eu/checkout'
          },
          {
            headers: {
              'Authorization': 'Bearer sup_sk_pcFn5WmqSHfhO91Okdll2jl7oVWyXzUjo',
              'Content-Type': 'application/json'
            }
          }
        );
    } catch (error) {
        console.error("Error initiating checkout:", error.response?.data || error.message);
        alert("Checkout failed. Please try again.");
    }
};

///////////////////
const initiateCheckoutBuyMinutes = async (amountInCents, minutesToBuy, selectedTagId) => {
  //console.log("Initiating checkout for device:", deviceId);
  // Ensure amount is an integer in cents
 //const parsedAmount = parseInt(amount, 10);

  try {
    const response = await axios.post(
        'https://api.sumup.com/v0.1/merchants/MEYZXRTU/readers/rdr_1EA1J3XFX485QTWHAGX9WDSB2D/checkout',
        // '{"total_amount": {"currency": "EUR", "minor_unit": 2, "value": 1000}, "description": "Minute Bar Checkout", "pay_to_terminal": true, "return_url": "https://www.minutebar.eu/checkout"}',
        {
          'total_amount': {
            'currency': 'EUR',
            'minor_unit': 2,
            'value': amountInCents
          },
          'description': selectedTagId + '| Minutes To Buy: ' +  minutesToBuy,
          'pay_to_terminal': true,
          'return_url': 'https://www.minutebar.eu/checkout'
        },
        {
          headers: {
            'Authorization': 'Bearer sup_sk_pcFn5WmqSHfhO91Okdll2jl7oVWyXzUjo',
            'Content-Type': 'application/json'
          }
        }
      );
  } catch (error) {
      console.error("Error initiating checkout:", error.response?.data || error.message);
      alert("Checkout failed. Please try again.");
  }
};
///////////////////

const deletePairedReader = async () => {
  const access_token = localStorage.getItem("access_token");

  if (!access_token || !merchantCode) {
    setMessage("Failed to delete reader: Missing token or merchant code.");
    return;
  }

  try {
    // ✅ Step 1: Fetch all readers
    const response = await axios.get(
      `https://api.sumup.com/v0.1/merchants/${merchantCode}/readers`,
      { headers: { Authorization: `Bearer ${access_token}` } }
    );

    console.log("Paired Devices:", response.data);

    // ✅ Step 2: Check if a reader is paired
    const devices = response.data.items || [];
    if (devices.length === 0) {
      setMessage("No paired reader found.");
      return;
    }

    // ✅ Step 3: Delete the first paired reader
    const readerId = devices[0].id;
    await axios.delete(
      `https://api.sumup.com/v0.1/merchants/${merchantCode}/readers/${readerId}`,
      { headers: { Authorization: `Bearer ${access_token}` } }
    );

    setMessage("Reader deleted successfully. Please re-pair your device.");
    console.log(`Deleted reader: ${readerId}`);

  } catch (error) {
    console.error("Error deleting paired reader:", error.response?.data || error.message);
    setMessage("Failed to delete reader.");
  }
};

  useEffect(() => {
      if (location.state?.transactionId) {
        setTransactionId(location.state.transactionId);
        localStorage.setItem('transactionId', location.state.transactionId);

        setTimeout(() => {
        setTransactionId('');
        localStorage.removeItem('transactionId');
      }, 30000); // Clear after 30 seconds

      }

      //fetchPairedReader();  // New function to verify paired SumUp reader
  }, [location]);



  const loadActiveCheckIns = async () => {
    const checkIns = await getAllActiveCheckIns();
    setActiveCheckIns(checkIns);
  };

  const startNFCScan = async () => {
    if (!('NDEFReader' in window)) {
      setMessage(translations.nfcUnsupported);
      return;
    }
    try {
      const ndef = new NDEFReader();
      await ndef.scan();
      setMessage(translations.nfcActivated);

      ndef.onreading = async (event) => {
        const tagId = event.serialNumber;
        await handleCheckOut(tagId);
      };

      ndef.onreadingerror = () => {
        setMessage(translations.readingError);
      };
    } catch (error) {
      setMessage(`NFC error: ${error.message}`);
    }
  };

  const handleCheckOut = async (tagId) => {
    const lastCheckIn = await getCheckInRecord(tagId);

    if (lastCheckIn && lastCheckIn.isCheckIn) {
      const timestamp = Date.now();
      let minutesSpent = Math.floor((timestamp - lastCheckIn.timestamp) / 60000);
      let cost = (minutesSpent * pricePerMinute).toFixed(2);

      if (minutesSpent < 60) {
        cost = (60 * pricePerMinute).toFixed(2);
        minutesSpent = 60;
      }

      // Convert cost to cents before passing it to checkout
      const amountInCents = Math.round(parseFloat(cost) * 100);

      setCheckedOutCards(prevCards => [
        ...prevCards,
        { tagId, minutesSpent, cost }
      ]);

      setMessage(`${translations.checkOutSuccessful || texts.checkOutSuccessful} ${tagId}.`);
      await initiateCheckout(amountInCents, minutesSpent); // Try to fetch reader again

      await removeCheckInRecord(tagId);
    } else {
      setMessage('No active check-in found for this tag.');
    }
  };






  const checkPaymentStatus = async (txId) => {
      if (!txId) {
        setMessage('No transaction to check.');
        return;
      }
      try {
        const response = await axios.get(`https://api.sumup.com/v1.1/receipts/${txId}`, {
          headers: { 'Authorization': `Bearer ${API_KEY}` },
        });
        setMessage(`Transaction Status: ${response.data.transaction_data.status}`);
      } catch (error) {
        console.error('Error checking transaction status:', error);
        setMessage('Failed to retrieve transaction status. Please try again later.');
      }
    };

  const pairReader = async () => {
    try {
      const response = await axios.post(`https://api.sumup.com/v0.1/merchants/${merchantCode}/readers`, {
        pairing_code: pairingCode
      }, {
        headers: { 'Authorization': `Bearer ${API_KEY}` }
      });
      console.log('RESPONSE PAIRING: ', response)
      setReader(response.data);
      setMessage('Reader paired successfully!');
      setShowPairingDialog(false);
    } catch (error) {
      console.error('Error pairing reader:', error);
      setMessage('Failed to pair reader. Please try again.');
    }
  };

  const buyMinutes = async () => {
    if (!selectedTagId) {
        setMessage(translations.scanTag);
        await startTagScan(); // Start NFC scan automatically
        return;
    }

    if (!minutesToBuy || minutesToBuy <= 0) {
        setMessage("Please select a valid minute package.");
        return;
    }

    // Ensure minutesToBuy is properly defined and converted to float
    const amount = parseFloat(minutesToBuy * pricePerMinute).toFixed(2);
    const amountInCents = Math.round(parseFloat(amount) * 100);

    console.log(`Selected minutes: ${minutesToBuy}, Amount: ${amount}, Amount in cents: ${amountInCents}`);
      setMessage(`Selected minutes: ${minutesToBuy}, Amount: ${amount}, Amount in cents: ${amountInCents}`);
    // Ensure SumUp API is called with the correct amount
    await initiateCheckoutBuyMinutes(amountInCents, minutesToBuy, selectedTagId);
};


 const startTagScan = async () => {
    console.log('In Scan Buy Minutes')
    if (!('NDEFReader' in window)) {
      setMessage(translations.nfcUnsupported);
      return;
    }
    try {
      const ndef = new NDEFReader();
      await ndef.scan();
      setMessage(translations.scanMessageBuy);

      ndef.onreading = async (event) => {
        setSelectedTagId(event.serialNumber);
        setMessage(`${translations.checkOutSuccessful} ${event.serialNumber}`);
      };

      ndef.onreadingerror = () => {
        setMessage(translations.readingError);
      };
    } catch (error) {
      setMessage(`NFC error: ${error.message}`);
    }
  };


  return (
      <div className="home-container">
        <div className="content-wrapper">
          {/* Linked Minute Bar Logo */}
          <a href="https://www.minutebar.eu" target="_blank" rel="noopener noreferrer">
            <img
              src="https://www.minutebar.eu/bh2025/images/logo.png"
              alt="Minute Bar Logo"
              className="logo"
              style={{ cursor: 'pointer', width: '100px', height: '100px' }}
            />
          </a>

          <h2>{translations.pageTitle}</h2>
          <img
            src="https://www.minutebar.eu/beahost2025/OV-chipkaart_logo.png"
            alt="OV Chip Card Logo"
            className="logo"
            style={{ cursor: 'pointer', width: '60px', height: '40px' }}
          />
          <p>{message}</p>
          <h3>{translations.activeCheckIns || texts.activeCheckIns} ({activeCheckIns.length}):</h3>
          <ul>
            {activeCheckIns.map(({ tagId, checkInTime }) => (
              <li key={tagId}>
                <strong>{translations.tagId || texts.tagId}</strong> {tagId} <br />
                <strong>{translations.checkInTime || texts.checkInTime}</strong> {checkInTime}
              </li>
            ))}
          </ul>
          <ul>
            {checkedOutCards.map((card, index) => (
              <li key={index}>
                <p>{translations.tagId}: {card.tagId}</p>
                <p>{translations.timeSpent}: {card.minutesSpent} minutes</p>
                <p>{translations.cost}: €{card.cost}</p>
              </li>
            ))}
          </ul>

          {/* Linked Pair Solo Reader Text */}
          <a
            href="https://developer.sumup.com/terminal-payments/guides/pairing-solo"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
          >
            {translations.pairDevice}
          </a>

          {/* Pairing Icon */}
          <img
            src="https://www.minutebar.eu/beahost2025/pairing_icon.png"
            alt="Pair Device"
            className="pairing-icon"
            style={{ cursor: 'pointer', width: '50px', height: '50px' }}
            onClick={() => setShowPairingDialog(true)}
          />

          {/* Pairing Input Dialog */}
          {showPairingDialog && (
            <div className="pairing-dialog">
              <p>{translations.enterPairingCode}</p>
              <input
                type="text"
                value={pairingCode}
                onChange={(e) => setPairingCode(e.target.value)}
              />
              <button onClick={pairReader}>{translations.pairNow}</button>
            </div>
          )}

          <h2>Buy Minutes</h2>

          <button onClick={startTagScan}>{translations.scanTag}</button>
          {selectedTagId && (
            <>
              <p>Tag ID: {selectedTagId}</p>
              <select
                  value={minutesToBuy}
                  onChange={(e) => setMinutesToBuy(parseInt(e.target.value, 10))}>
                  {minuteOptions.map((minutes) => (
                      <option key={minutes} value={minutes}>
                          {minutes} minutes (€{(minutes * pricePerMinute).toFixed(2)})
                      </option>
                  ))}
              </select>
              {/* Pairing Icon */}
              <img
                src="https://www.minutebar.eu/beahost2025/buy_minutes_contactless.png"
                alt="Buy Minutes"
                className="pairing-icon"
                style={{ cursor: 'pointer', width: '75px', height: '50px' }}
                onClick={buyMinutes}
              />
            </>
          )}
          <br></br>
          <img
            src="https://www.minutebar.eu/beahost2025/cards-mobile-new.png"
            alt="OV Chip Card Logo"
            className="logo"
            style={{ cursor: 'pointer', width: '250px', height: '75px' }}
          />
        </div>
      </div>
  );


}
