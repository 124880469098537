// Import dependencies
import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CreditsContext from "./CreditsContext";
import "./App.css";
import axios from "axios";

const TRANSLATE_API_KEY = process.env.REACT_APP_TRANSLATE_API_KEY;
const STRIPE_SECRET_KEY = process.env.REACT_APP_STRIPE_LIVE_KEY;

function Home() {
  const { credits, setCredits } = useContext(CreditsContext);
  const [userEmail, setUserEmail] = useState("");
  const [loadingSubscription, setLoadingSubscription] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [translations, setTranslations] = useState({});
  const [userLanguage, setUserLanguage] = useState("en");

  // Load saved email and subscription details on first render
  useEffect(() => {
    const savedEmail = localStorage.getItem("lastCheckedEmail");
    if (savedEmail) {
      setUserEmail(savedEmail);
      fetchSubscriptionStatus(savedEmail);
    }
  }, []);

  // Detect user's language
  useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then((response) => response.json())
      .then((data) => {
        const detectedLanguage = data.languages?.split(",")[0] || "en";
        setUserLanguage(detectedLanguage);
      })
      .catch(() => setUserLanguage("en"));
  }, []);

  // Fetch and apply translations
  useEffect(() => {
    const translateTexts = async () => {
      try {
        const texts = {
          title: "NFC Time Application for Hosts",
          platform: "Minute Bar® | Platform",
          noCredits: "No credits remaining.",
          viewPricingPlans: "Please view pricing plans.", // NEW translation key
          availableCredits: "Credits available:",
          checkSubscription: "Check Subscription",
          enterEmail: "Enter your email address",
          goToCheckIn: "Go to Check-In",
          goToCheckOut: "Go to Check-Out",
          about: "About",
          aboutMinuteBar: "About Minute Bar®",
          pricing: "Pricing (10 days FREE)",
          plan: "Plan:",
          status: "Status:",
          nextBilling: "Next Billing:",
          privacyPolicy: "Privacy Policy",
          disclaimer: "Disclaimer",
          since: "since 2015",
          allRightsReserved: "All rights reserved.",
        };

        const translationPromises = Object.keys(texts).map((key) =>
          axios
            .post(
              `https://translation.googleapis.com/language/translate/v2?key=${TRANSLATE_API_KEY}`,
              { q: texts[key], target: userLanguage }
            )
            .then((response) => ({
              key,
              translation: response.data.data.translations[0].translatedText,
            }))
        );

        const results = await Promise.all(translationPromises);
        const translationResults = results.reduce((acc, { key, translation }) => {
          acc[key] = translation;
          return acc;
        }, {});

        setTranslations(translationResults);
      } catch (error) {
        console.error("Error translating text:", error);
      }
    };

    if (userLanguage !== "en") {
      translateTexts();
    }
  }, [userLanguage]);

  const handleUseCredit = (e) => {
    if (credits <= 0) {
      e.preventDefault(); // Prevent navigation if no credits
      alert(translations.noCredits || "❌ No credits remaining. Please subscribe for more credits.");
    } else {
      const newCredits = Math.max(credits - 1, 0);
      setCredits(newCredits);
      localStorage.setItem("beahostCredits", newCredits);
      console.log(`✅ Action successful. Remaining credits: ${newCredits}`);
    }
  };

  const fetchSubscriptionStatus = async () => {
    console.log("🔎 Checking subscription for email:", userEmail);
    if (!userEmail) {
      alert(translations.enterEmail || "Please enter an email address.");
      return;
    }

    setLoadingSubscription(true);
    try {
      const customerResponse = await fetch(
        `https://api.stripe.com/v1/customers?email=${userEmail}`,
        { headers: { Authorization: `Bearer ${STRIPE_SECRET_KEY}` } }
      );
      const customerData = await customerResponse.json();
      if (!customerData.data || customerData.data.length === 0) {
        alert(translations.noCredits || "❌ No customer found for this email. Credits reset to 0.");
        setCredits(0);
        localStorage.setItem("beahostCredits", 0);
        setLoadingSubscription(false);
        return;
      }

      const customerId = customerData.data[0].id;
      const subscriptionResponse = await fetch(
        `https://api.stripe.com/v1/subscriptions?customer=${customerId}`,
        { headers: { Authorization: `Bearer ${STRIPE_SECRET_KEY}` } }
      );
      const subscriptionData = await subscriptionResponse.json();

      if (subscriptionData.data && subscriptionData.data.length > 0) {
        const subscription = subscriptionData.data[0];
        setSubscriptionDetails({
          plan: subscription.plan.nickname || "Unknown Plan",
          status: subscription.status,
          nextBilling: new Date(subscription.current_period_end * 1000).toLocaleDateString(),
        });

        if (subscription.status === "active" || subscription.status === "trialing") {
          setCredits(10);
          localStorage.setItem("beahostCredits", 10);
          alert("✅ Subscription is active! 10 credits granted.");
        } else {
          setCredits(0);
          localStorage.setItem("beahostCredits", 0);
          alert("❌ Subscription is inactive. Credits reset to 0.");
        }
      } else {
        setCredits(0);
        localStorage.setItem("beahostCredits", 0);
        alert("❌ No active subscription found. Credits reset to 0.");
      }
    } catch (error) {
      console.error("❌ Error fetching subscription:", error);
      alert("Error retrieving subscription. Check console for details.");
    } finally {
      setLoadingSubscription(false);
    }
  };

  return (
    <div className="home-container">
      <div className="content-wrapper">
        <h1>{translations.title || "NFC Time Application Host"}</h1>
        <h2>{translations.platform || "Minute Bar® | Platform"}</h2>

        <p className="credits-info">
          {credits > 0 ? (
            <>
              {translations.availableCredits || "Credits available:"}{" "}
              <span className="font-bold">{credits}</span>
            </>
          ) : (
            <>
              <span>{translations.noCredits || "No credits remaining."}</span> <br />
              <Link to="/pricing" className="pricing-link">
                {translations.viewPricingPlans || "Please view pricing plans."}
              </Link>
            </>
          )}
        </p>



        <nav>
          <Link to="/checkin" className={`button-link ${credits <= 0 ? "disabled" : ""}`} onClick={handleUseCredit}>
            {translations.goToCheckIn || "Go to Check-In"}
          </Link>
          <Link to="/checkout" className={`button-link checkout ${credits <= 0 ? "disabled" : ""}`} onClick={handleUseCredit}>
            {translations.goToCheckOut || "Go to Check-Out"}
          </Link>
          <Link to="/about" className="button-link about">{translations.about || "About"}</Link>
          <Link to="/aboutminutebar" className="button-link about">{translations.aboutMinuteBar || "About Minute Bar®"}</Link>
          <Link to="/pricing" className="button-link pricing">{translations.pricing || "Pricing (10 days FREE)"}</Link>
        </nav>
        <p></p>
        {/* Subscription Input & Button (Placed Below Buttons Now) */}
        <div className="subscription-section">
          <input
            type="email"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            placeholder={translations.enterEmail || "Enter your email"}
            className="tag-input"
          />
          <button
            className="button-link clear-button"
            onClick={fetchSubscriptionStatus}
            disabled={loadingSubscription || !userEmail}
          >
            {loadingSubscription ? "Checking..." : translations.checkSubscription || "Check Subscription"}
          </button>
          <Link to="/chatbot" className="button-link">Chat with Us</Link>
        </div>

        {subscriptionDetails && (
          <div className="subscription-info">
            <p><b>{translations.plan || "Plan:"}</b> {subscriptionDetails.plan}</p>
            <p><b>{translations.status || "Status:"}</b> {subscriptionDetails.status}</p>
            <p><b>{translations.nextBilling || "Next Billing:"}</b> {subscriptionDetails.nextBilling}</p>
          </div>
        )}

        <div className="footer-links">
          <p><Link to="/privacy">Privacy Policy</Link></p>
          <p><Link to="/disclaimer">Disclaimer</Link></p>
        </div>

        <footer className="footer-copyright">
          <p>&copy; {new Date().getFullYear()} - Minute Bar&reg;</p>
          <p>
            <i>
              <a href="https://www.boip.int/en/trademarks-register?app=%2Fitem%2Fbx1316942&query=minute+bar"
                 target="_blank" rel="noopener noreferrer">since 2015</a>
            </i>
          </p>
          <p>All rights reserved.</p>
        </footer>

      </div>
    </div>
  );
}

export default Home;
