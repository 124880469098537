import React, { useState, useEffect } from 'react';
import './App.css';
import axios from 'axios';

const TRANSLATE_API_KEY = process.env.REACT_APP_TRANSLATE_API_KEY;

export default function Disclaimer() {
  const [translations, setTranslations] = useState({});
  const [userLanguage, setUserLanguage] = useState('en');

  const texts = {
    title: 'Disclaimer',
    paragraph1:
      'The information provided by this application is for general informational purposes only. All information in the application is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, or completeness of any information in the application.',
    paragraph2:
      'Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the application or reliance on any information provided. Your use of the application and your reliance on any information is solely at your own risk.',
  };

  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        const detectedLanguage = data.languages?.split(',')[0] || 'en';
        setUserLanguage(detectedLanguage);
      })
      .catch(() => setUserLanguage('en'));
  }, []);

  useEffect(() => {
    const translateTexts = async () => {
      if (userLanguage === 'en') {
        setTranslations(texts);
        return;
      }
      try {
        const translationPromises = Object.keys(texts).map(key =>
          axios.post(`https://translation.googleapis.com/language/translate/v2?key=${TRANSLATE_API_KEY}`, {
            q: texts[key],
            target: userLanguage
          }).then(response => ({ key, translation: response.data.data.translations[0].translatedText }))
        );

        const results = await Promise.all(translationPromises);
        const translationResults = results.reduce((acc, { key, translation }) => {
          acc[key] = translation;
          return acc;
        }, {});

        setTranslations(translationResults);
      } catch (error) {
        console.error('Error translating texts:', error);
        setTranslations(texts);
      }
    };
    translateTexts();
  }, [userLanguage]);

  return (
    <div className="disclaimer-container">
      <h2>{translations.title || texts.title}</h2>
      <p>{translations.paragraph1 || texts.paragraph1}</p>
      <p>{translations.paragraph2 || texts.paragraph2}</p>
    </div>
  );
}
