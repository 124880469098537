import React from 'react';

export default function TermsAndConditions() {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
      <h1>Terms and Conditions</h1>
      <p>
        Welcome to Pay with BE a Host. These terms and conditions outline the rules and regulations for the use of our service.
      </p>
      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing or using our services, you agree to be bound by these terms. If you do not agree with any part of these terms, please do not use our services.
      </p>
      <h2>2. Services</h2>
      <p>
        Pay with BE a Host enables you to integrate your SumUp account for seamless payment processing. The use of this service is subject to compliance with SumUp's terms and conditions as well.
      </p>
      <h2>3. User Responsibilities</h2>
      <p>
        You are responsible for ensuring that the information you provide is accurate and up to date. You agree to use our service only for lawful purposes.
      </p>
      <h2>4. Disclaimer</h2>
      <p>
        We are not responsible for any errors, disruptions, or financial losses resulting from the use of our services or third-party integrations.
      </p>
      <h2>5. Amendments</h2>
      <p>
        We reserve the right to update these terms at any time. Continued use of the service constitutes your agreement to the updated terms.
      </p>
      <p>
        If you have any questions about these Terms and Conditions, please contact us at <a href="mailto:info@minutebar.eu">info@minutebar.eu</a>.
      </p>
    </div>
  );
}
