import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import NFCReader from "./NFCReader";
import CheckOut from "./CheckOut";
import Privacy from "./Privacy";
import Disclaimer from "./Disclaimer";
import Pricing from "./Pricing";
import OAuthCallback from "./OAuthCallback";
import Consent from "./Consent";
import TermsAndConditions from "./TermsAndConditions";
import Success from "./Success";
import AboutMinutebar from "./AboutMinutebar";
import CreditsContext from "./CreditsContext"; // Ensure correct import
import Chatbot from './Chatbot';

function App() {
  // Initialize credits from localStorage
  const [credits, setCredits] = useState(() => {
    const savedCredits = parseInt(localStorage.getItem("beahostCredits"), 10);
    return !isNaN(savedCredits) && savedCredits > 0 ? savedCredits : 0;
  });

  // Save credits to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem("beahostCredits", credits);
  }, [credits]);

  return (
    <CreditsContext.Provider value={{ credits, setCredits }}>
      <Router basename="/beahost2025">
        <Routes>
          <Route path="/oauth/callback" element={<OAuthCallback />} />
          <Route path="/consent" element={<Consent />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/success" element={<Success />} />
          <Route path="/checkin" element={<NFCReader />} />
          <Route path="/checkout" element={<CheckOut />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/aboutMinutebar" element={<AboutMinutebar />} />
          <Route path="/chatbot" element={<Chatbot />} />
        </Routes>
      </Router>
    </CreditsContext.Provider>
  );
}

export default App;
